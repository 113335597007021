<template>
  <div class="mt-12">
    <help-dialog
      :path="headingPath"
      tag="h2"
      css-classes="mb-2"
    ></help-dialog>

    <v-row>
      <v-col cols="12" md="6">
        <form-builder
          ref="form"
          :config="form.config"
          :data="form.data"
          :errors="form.errors"
          :rules="form.rules"
          :transformers="form.transformers"
          @submit="handleSubmit"
          @reset="handleReset"
        >
          <template #form.footer="{ submit, submitText, valid }">
            <v-btn
              color="primary"
              :disabled="!valid"
              :loading="creatingHandle"
              @click="submit"
            >
              {{ submitText }}
            </v-btn>
          </template>
        </form-builder>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import FormBuilder from '../form/FormBuilder'
import HelpDialog from '../HelpDialog'

import { getFileUrl } from '@/api/files.api'
import { noFileExt, required } from '@/helpers/validators.helpers'
import { getHttpValidationErrorDetails } from '@/helpers/api.helper'

export default {
  name: 'DocumentForm',
  components: {
    FormBuilder,
    HelpDialog
  },
  data () {
    return {
      getFileUrl,
      form: {
        config: [
          {
            name: 'name',
            component: 'v-text-field'
          },
          {
            name: 'filename',
            component: 'v-text-field',
            props: {
              suffix: this.$tp('components.management.filenameSuffixes.pdfFile')
            }
          },
          {
            name: 'comment',
            component: 'v-textarea'
          }
        ],
        data: {
          name: '',
          filename: '',
          comment: ''
        },
        errors: {},
        rules: {
          name: [
            required
          ],
          filename: [
            noFileExt
          ]
        }
      }
    }
  },
  methods: {
    ...mapActions('documents', ['createDocument', 'deleteDocument']),
    ...mapActions('messageQueue', ['queueSuccess']),
    async handleSubmit ({ name, comment, filename }) {
      await this.createDocument({
        name,
        comment,
        filename,
        handlers: {
          '2xx': () => {
            this.resetForm()
            return this.$tp('components.form.messages.success')
          },
          422: (response) => {
            const errors = getHttpValidationErrorDetails(response)

            if (errors) {
              errors.forEach(({ field, message }) => {
                this.$set(this.form.errors, field, message)
              })
            }

            return this.$t('global.form.errorMessages.invalid')
          },
          '4xx': () => {
            return this.$t('global.form.errorMessages.invalid')
          }
        }
      })
    },
    handleReset (field) {
      this.$set(this.form.errors, field, '')
    },
    resetForm () {
      this.form.data = {
        name: '',
        comment: ''
      }
    }
  },
  computed: {
    ...mapGetters('documents', ['creatingHandle']),
    headingPath () {
      return 'components.form.heading'
    }
  },
  inject: [
    '$tp'
  ]
}
</script>
