var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-16"},[_c('help-dialog',{attrs:{"path":_vm.headingPath,"tag":"h3","css-classes":"mb-2"}}),_c('data-table',{attrs:{"table-name":"versions.table","data-table":_vm.dataTable,"actions":_vm.actions,"search-action":_vm.searchAction},scopedSlots:_vm._u([{key:"item.validFrom",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'text-decoration-line-through': _vm.isVersionOverridden(item) }},[_c('formatted-date',{attrs:{"date":item.validFrom,"time":""}}),(_vm.isVersionOverridden(item))?_c('v-tooltip',{attrs:{"max-width":"300","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary","small":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$icons.mdiHelpCircle)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.overriddenHint))])]):_vm._e()],1)]}},{key:"item.uuid",fn:function(ref){
var item = ref.item;
return [_c('copy',{attrs:{"display":_vm.shortenUuid(item.uuid),"text":item.uuid}})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-4",attrs:{"colspan":headers.length}},[_c('embedded-object',{attrs:{"type":"application/pdf","width":"100%","source":_vm.getFileUrl(item.fileUuid),"download":_vm.getFileUrl(item.fileUuid, true)}},[_vm._v(" "+_vm._s(item.fileUuid)+" ")]),(item.comment.length > 0)?[_c('v-divider',{staticClass:"my-4"}),_c('v-container',{staticClass:"pa-0 mx-0 mb-0 mt-2",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.createdUser.preferredUsername))])]),_c('v-col',{staticClass:"text-right body-2"},[_c('formatted-date',{attrs:{"date":item.createdOn}})],1)],1),_c('p',{staticClass:"mt-2 body-2"},[_vm._v(_vm._s(item.comment))])],1)]:_vm._e()],2)]}}])}),(_vm.activeDocumentComments.length > 0)?[_c('h3',{staticClass:"mt-16"},[_vm._v(_vm._s(_vm.commentsHeading))]),_vm._l((_vm.activeDocumentComments),function(ref,i){
var comment = ref.comment;
var createdUser = ref.createdUser;
var createdOn = ref.createdOn;
return _c('v-container',{key:i,staticClass:"pa-0 mx-0 mb-0 mt-2",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(createdUser.preferredUsername))])]),_c('v-col',{staticClass:"text-right body-2"},[_c('formatted-date',{attrs:{"date":createdOn}})],1)],1),_c('p',{staticClass:"mt-2 body-2"},[_vm._v(_vm._s(comment))])],1)})]:_vm._e(),_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[(_vm.deleteDocumentItem)?_c('v-card',{staticClass:"px-4 py-4"},[_c('v-card-title',[_vm._v(_vm._s(_vm.deleteDialogTitle))]),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.resetDeleteDialog}},[_vm._v(_vm._s(_vm.deleteDialogCancelText))]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":_vm.confirmDelete}},[_vm._v(_vm._s(_vm.deleteDialogConfirmText))])],1)],1):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }