<template>
  <div class="mt-12">
    <help-dialog
      :path="headingPath"
      tag="h2"
      css-classes="mb-2"
    ></help-dialog>

    <data-table
      table-name="management.table"
      :data-table="dataTable"
      :actions="actions"
      :search-action="searchAction"
    >
      <template #item.name="{ item }">
        <div
          class="d-flex flex-row align-center"
        >
          <v-tooltip
            right
            v-if="item.documentVersions.length === 0"
          >
            <template #activator="{ on, attrs }">
              <v-icon
                v-on="on"
                v-bind="attrs"
                color="red"
                class="mr-2"
              >
                {{ $icons.mdiAlertCircleOutline }}
              </v-icon>
            </template>
            <span
              v-html="noDocumentVersionsTooltip"
            ></span>
          </v-tooltip>
          <copy :text="item.name"></copy>
        </div>
      </template>

      <template #item.createdOn="{ item }">
        <formatted-date :date="item.createdOn"></formatted-date>
      </template>

      <template #item.updatedOn="{ item }">
        <formatted-date :date="item.updatedOn"></formatted-date>
      </template>

      <template #item.uuid="{ item }">
        <copy :text="item.uuid" :display="shortenUuid(item.uuid)"></copy>
      </template>
    </data-table>

    <side-panel v-model="sidePanel">
      <div v-if="activeDocument">
        <h2 class="mb-4">
          <div class="mb-4">
            <div class="d-flex justify-space-between align-baseline mx-n2">
              <v-tooltip
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <span
                    class="px-2 text-truncate"
                    v-on="on"
                    v-bind="attrs"
                  >{{ activeDocument.name }}</span>
                </template>
                <span>{{ activeDocument.name }}</span>
              </v-tooltip>
              <div
                class="px-2"
              >
                <v-chip color="primary" small>{{ versionsCountText }}</v-chip>
              </div>
            </div>
            <div class="text-body-2 grey--text">
              <span v-if="filename">({{ filename }})</span>
              <span v-else>{{ noFilenameText }}</span>
              <component-guard
                class="d-inline"
                :roles="['admin', 'manager']"
              >
                <v-btn
                  icon
                  x-small
                  @click="handleEdit"
                >
                  <v-icon>{{ $icons.mdiPencil }}</v-icon>
                </v-btn>
              </component-guard>
            </div>
          </div>
          <div class="d-flex flex-column text-subtitle-1 mb-10">
            <div class="d-flex justify-space-between">
              <span class="font-weight-bold">{{ activeDocument.createdUser.preferredUsername }}</span>
              <span><formatted-date :date="activeDocument.createdOn"></formatted-date></span>
            </div>
            <div class="mt-2" v-if="activeDocument.comment">
              {{ activeDocument.comment }}
            </div>
          </div>
        </h2>

        <component-guard
          :roles="['admin', 'manager']"
        >
          <add-document-version-form></add-document-version-form>
        </component-guard>

        <document-versions></document-versions>
      </div>
    </side-panel>

    <v-dialog
      width="600"
      v-model="deleteDialog"
    >
      <v-card
        class="px-4 py-4"
        v-if="deleteDocumentItem"
      >
        <v-card-title>{{ deleteDialogTitle }}</v-card-title>

        <v-card-actions>
          <v-btn text color="primary" @click="resetDeleteDialog">{{ deleteDialogCancelText }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="red" @click="confirmDelete">{{ deleteDialogConfirmText }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      width="600"
      v-model="editFilenameDialog"
    >
      <v-card
        class="pa-4"
      >
        <v-card-title
          v-text="editFilenameDialogTitle"
          class="mb-2"
        ></v-card-title>
        <v-card-text>
          <form-builder
            :config="editFilenameForm.config"
            :data="editFilenameForm.data"
            :errors="editFilenameForm.errors"
            :rules="editFilenameForm.rules"
            @reset="handleReset"
            @cancel="handleCancel"
            @submit="handleSubmit"
            ref="editFilenameForm"
            :form-name="editFilenameFormName"
          ></form-builder>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import AddDocumentVersionForm from './AddDocumentVersionForm'
import Copy from '../Copy'
import DataTable from '../DataTable'
import DocumentVersions from './DocumentVersions'
import FormattedDate from '../FormattedDate'
import HelpDialog from '../HelpDialog'
import SidePanel from '../SidePanel'

import { getFileUrl } from '@/api/files.api'
import ComponentGuard from '../ComponentGuard'

import UserRoleAwareMixin from '@/mixins/userRoleAware.mixin'

import { shortenUuid } from '@/helpers/utility.helper'

import FormBuilder from '@/components/form/FormBuilder'

export default {
  name: 'DocumentManagement',
  mixins: [
    UserRoleAwareMixin
  ],
  components: {
    AddDocumentVersionForm,
    Copy,
    DataTable,
    DocumentVersions,
    FormattedDate,
    HelpDialog,
    SidePanel,
    ComponentGuard,
    FormBuilder
  },
  data () {
    return {
      deleteDocumentItem: null,
      deleteDialog: false,
      primaryField: 'uuid',
      searchTerm: '',
      editFilenameDialog: false,
      editFilenameFormName: 'editFilenameForm',
      editFilenameForm: {
        config: [
          {
            name: 'filename',
            component: 'v-text-field',
            props: {
              required: true,
              clearable: true,
              suffix: this.$tp('components.management.filenameSuffixes.pdfFile')
            }
          }
        ],
        data: {
          filename: ''
        },
        errors: {
          filename: ''
        },
        rules: {
          filename: []
        }
      }
    }
  },
  created () {
    this.getDocumentHandles({})
  },
  methods: {
    ...mapActions('documents', ['getDocumentHandles', 'loadDocument', 'updateDocument', 'resetDocument', 'deleteDocument', 'setDocumentsSidePanel']),
    ...mapActions('messageQueue', ['queueError', 'queueSuccess']),
    async openSidePanel ({ uuid }) {
      this.resetDocument()
      await this.loadDocument({ uuid })
      this.sidePanel = true
    },
    searchHandler (term) {
      this.searchTerm = term
    },
    openFile (uuid) {
      const url = getFileUrl(uuid)

      window.open(url, '_blank')
    },
    handleDelete (item) {
      this.deleteDocumentItem = item
      this.deleteDialog = true
    },
    resetDeleteDialog () {
      this.deleteDialog = false
      this.deleteDocumentItem = null
    },
    confirmDelete () {
      this.deleteDocument({
        uuid: this.deleteDocumentItem.uuid,
        handlers: {
          '2xx': () => this.deleteSuccessMessage,
          '4xx': () => this.deleteErrorMessage
        }
      })
      this.resetDeleteDialog()
    },
    shortenUuid,
    handleEdit () {
      this.editFilenameDialog = true
    },
    handleCancel () {
      this.editFilenameDialog = false
    },
    handleReset (formField) {
      this.$set(this.editFilenameForm.errors, formField, '')
    },
    handleSubmit (formData) {
      const uuid = this.activeDocument.uuid
      const data = {
        name: this.activeDocument.name,
        filename: formData.filename,
        comment: this.activeDocument.comment
      }
      const handlers = {
        '2xx': () => this.updateFilenameSuccessMessage,
        '4xx': () => this.updateFilenameErrorMessage,
        '5xx': () => this.updateFilenameErrorMessage
      }
      this.updateDocument({ uuid, data, handlers })
        .then((success) => {
          if (success) {
            this.editFilenameDialog = false
          }
        })
    },
    isDeleteButtonDisabled (item) {
      return (!this.isAdmin && !this.isManager) || item.distinctFolderUuids.length > 0
    }
  },
  computed: {
    ...mapGetters('documents', ['documents', 'loading', 'activeDocument', 'activeDocumentVersions']),
    ...mapState('documents', ['documentsSidePanel']),
    dataTable () {
      return {
        headers: this.headers,
        items: this.documents,
        loading: this.loading,
        showSelect: false,
        itemKey: this.primaryField,
        sortBy: 'updatedOn',
        sortDesc: true,
        disablePagination: true,
        hideDefaultFooter: true,
        options: {},
        search: this.searchTerm,
        itemClass: (item) => {
          if (this.activeDocument && this.activeDocument.uuid === item.uuid) {
            return 'grey lighten-3'
          }

          return ''
        },
        customFilter (value, search, item) {
          if (!search) {
            return true
          }

          if (!value) {
            return false
          }

          if (value.toLowerCase().includes(search.toLowerCase())) {
            return true
          }

          const documentVersions = item?.documentVersions ?? []

          if (documentVersions.length < 1) {
            return false
          }

          return documentVersions.reduce((match, { uuid }) => {
            return match || uuid.toLowerCase().includes(search.toLowerCase())
          }, false)
        }
      }
    },
    actions () {
      return [
        { icon: this.$icons.mdiDelete, handler: this.handleDelete, disabled: this.isDeleteButtonDisabled, tooltip: this.deleteButtonTooltip },
        { icon: this.$icons.mdiChevronRight, handler: this.openSidePanel, tooltip: this.detailButtonTooltip }
      ]
    },
    versionsCountText () {
      return this.$tc(`${this.pageTranslationKey}.components.versions.versionsCount`, this.activeDocumentVersions.length)
    },
    componentTranslationKey () {
      return `${this.pageTranslationKey}.components.management`
    },
    headingPath () {
      return `${this.componentTranslationKey}.heading`
    },
    tableTranslationKey () {
      return `${this.componentTranslationKey}.table`
    },
    headers () {
      return [
        { value: 'name' },
        { value: 'createdOn' },
        { value: 'updatedOn' },
        { value: 'uuid' },
        { value: 'actions', sortable: false, align: 'end' }
      ]
    },
    searchAction () {
      return {
        label: this.$t(`${this.tableTranslationKey}.search.label`),
        hint: this.$t(`${this.tableTranslationKey}.search.hint`),
        handler: this.searchHandler
      }
    },
    deleteDialogTitle () {
      return this.$t(`${this.tableTranslationKey}.dialogs.delete.title`, {
        document: this.deleteDocumentItem?.name ?? ''
      })
    },
    deleteDialogCancelText () {
      return this.$t(`${this.tableTranslationKey}.dialogs.delete.buttons.cancel`)
    },
    deleteDialogConfirmText () {
      return this.$t(`${this.tableTranslationKey}.dialogs.delete.buttons.confirm`)
    },
    deleteErrorMessage () {
      return this.$t(`${this.tableTranslationKey}.dialogs.delete.messages.error`)
    },
    deleteSuccessMessage () {
      return this.$t(`${this.tableTranslationKey}.dialogs.delete.messages.success`)
    },
    deleteButtonTooltip () {
      return this.$t(`${this.tableTranslationKey}.actions.delete`)
    },
    detailButtonTooltip () {
      return this.$t(`${this.tableTranslationKey}.actions.detail`)
    },
    noDocumentVersionsTooltip () {
      return this.$t(`${this.tableTranslationKey}.tooltips.noDocumentVersions`)
    },
    filename () {
      const filename = this.activeDocument.filename || null
      return filename !== null
        ? `${filename}${this.$tp('components.management.filenameSuffixes.pdfFile')}`
        : null
    },
    editFilenameDialogTitle () {
      return this.$tp('components.management.dialogs.editFilename.title')
    },
    updateFilenameSuccessMessage () {
      return this.$tp('components.management.dialogs.editFilename.messages.success')
    },
    updateFilenameErrorMessage () {
      return this.$tp('components.management.dialogs.editFilename.messages.error')
    },
    noFilenameText () {
      return this.$tp('components.management.noFilenameText')
    },
    sidePanel: {
      get () {
        return this.documentsSidePanel
      },
      set (value) {
        this.setDocumentsSidePanel(value)
      }
    }
  },
  inject: [
    '$tp',
    'pageTranslationKey'
  ],
  watch: {
    sidePanel (value) {
      if (!value) {
        this.resetDocument()
      }
    },
    editFilenameDialog () {
      const filename = this.activeDocument.filename || ''
      this.$set(this.editFilenameForm.data, 'filename', filename)
      this.$nextTick(() => {
        this.$refs.editFilenameForm.reset()
      })
    }
  }
}
</script>
